import 'mdn-polyfills/NodeList.prototype.forEach';
import Highlight from './Highlight';

document.addEventListener('DOMContentLoaded', () => {
  const highlights = document.querySelectorAll('[data-highlight]');
  if (!highlights.length) {
    return;
  }
  highlights.forEach((element) => {
    const highlight = new Highlight(element);
    highlight.mount();
  });
});
