export default class Alert {
  constructor (element) {
    this.element = element;

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick (e) {
    const answer = confirm(this.element.dataset.alert);

    if(!answer) {
      e.preventDefault();
    }
  }

  mount () {
    this.element.addEventListener('click', this.handleClick);
  }

  unmount () {
    this.element.removeEventListener('click', this.handleClick);
  }
}
