import 'mdn-polyfills/NodeList.prototype.forEach';
import Alert from './Alert';

document.addEventListener('DOMContentLoaded', () => {
  const alerts = document.querySelectorAll('[data-alert]');
    if (!alerts.length) {
      return;
    }
    alerts.forEach((element) => {
      const alert = new Alert(element);
      alert.mount();
    });
});
