import { setCookie } from '../../../utils/cookies';

/**
 * Name: Highlight
 */

export default class Highlight {
  constructor (element) {
    this.element = element;
    this.close = document.querySelector('[data-highlight-close]');

    this.handleCloseButton = this.handleCloseButton.bind(this);
  }

  handleCloseButton () {
    this.unmount();
    this.element.remove();

    if (this.element.getAttribute('id')) {
      setCookie(this.element.getAttribute('id'), 1);
    }
  }

  mount () {
    this.close.addEventListener('click', this.handleCloseButton);
  }

  unmount () {
    this.close.removeEventListener('click', this.handleCloseButton);
  }
}
